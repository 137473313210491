#about {
  padding: 3rem 0;
  background-color: #f3f3f3;

  & > .container > .row {
    align-items: center;
    justify-content: center;
  }

  & .about-description {
    text-align: justify;

    & > h4 {
      text-align: center;
    }
  }

  & .about-image {
    margin-top: 3rem;

    & > img {
      max-width: 100%;
      margin: 0 auto;
      border-radius: 0.5rem;
      display: block;
    }
  }

  // Breakpoint: md
  @media screen and (min-width: 768px) {
    & > .container > .row {
      flex-direction: row-reverse;
    }

    & .about-image {
      margin-top: 0;
    }
  }
}
