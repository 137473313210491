#navbar {
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);

  .navbar-nav {
    padding-top: $navbar-nav-link-padding-x;

    & > a {
      padding: $navbar-nav-link-padding-x;
      border-radius: .3rem;
      color: $primary;

      &:hover {
        color: $white;
        background-color: $primary;
      }
    }
  }

  // Breakpoint: lg
  @media screen and (min-width: 992px) {
    .navbar-nav {
      padding-top: 0;

      & > a {
        margin-left: $navbar-nav-link-padding-x;
        margin-right: $navbar-nav-link-padding-x;
      }
    }
  }
}
