#banner {
  margin-top: 56px;
  padding: 3rem 0;

  & > .container > .row {
    align-items: center;
    justify-content: center;
  }

  & .banner-title {
    text-align: center;

    & > h1 {
      font-size: 2rem;
      font-weight: bold;

      & > span {
        color: $primary;
      }
    }

    & > h2 {
      font-size: 1.5rem;
    }
  }

  & .banner-links {
    margin: 0 -5px 0.5rem -5px;
    text-align: center;
    display: block;

    &__link {
      margin: 0 5px;
      display: inline-block;
    }
  }

  & .banner-button {
    text-align: center;
    display: block;
  }

  & .banner-image {
    margin-top: 3rem;

    & > img {
      max-width: 80%;
      margin: 0 auto;
      border-radius: 100%;
      background-color: $primary;
      display: block;
    }
  }

  // Breakpoint: sm
  @media screen and (min-width: 576px) {
    & .banner-title {
      text-align: left;
    }

    & .banner-links {
      text-align: left;
    }

    & .banner-button {
      text-align: left;
    }

    & .banner-image {
      margin-top: 0;

      & > img {
        max-width: 100%;
        margin: 0 0 0 auto;
      }
    }
  }

  // Breakpoint: lg
  @media screen and (min-width: 992px) {
    & .banner-title {
      & > h1 {
        font-size: 3rem;
      }

      & > h2 {
        font-size: 2rem;
      }
    }

    & .banner-image {
      & > img {
        max-width: 100%;
      }
    }
  }
}
